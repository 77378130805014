@import "./var";

$enable-negative-margins: true;

// $primary: $belizehole;
$primary: $orange;
$danger: $alizarin;
$info: $peterriver;
$warning: $sunflower;
$success: $emerald;
$dark: $midnightblue;
$secondary: $silver;

$colors: (
  "blue": $peterriver,
  "red": $alizarin,
  "orange": $orange,
  "yellow": $sunflower,
  "green": $emerald,
  "white": #fff,
  "gray": $concrete,
  "gray-dark": $asbestos,
) !default;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "info": $info,
  "warning": $warning,
  "success": $success,
  "secondary": $secondary,
  "dark": $dark,
  "light": $light,
);

@import "@coreui/coreui/scss/coreui.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700;1,800&display=swap");

body {
  background: #fff;
  font-family: "Source Sans Pro";
}

.custom-title {
  position: relative;
  span {
    position: relative;
    background: #fff;
    line-height: 1;
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding: 0 15px;
  }
  &::before {
    content: "";
    position: absolute;
    background: black;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
    z-index: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  h1,
  .h1 {
    font-size: 22px;
  }
  h2,
  .h2 {
    font-size: 20px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
  h4,
  .h4 {
    font-size: 16px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
}
