$turquoise: #1abc9c;
$greensea: #16a085;
$sunflower: #f1c40f;
$orange: #ff8a12;
$emerald: #2ecc71;
$nephritis: #27ae60;
$carrot: #e67e22;
$pumpkin: #d35400;
$peterriver: #3498db;
$belizehole: #2980b9;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$wetasphalt: #34495e;
$midnightblue: #2c3e50;
$concrete: #95a5a6;
$asbestos: #7f8c8d;

$dark: #4f5d73;
$light: #ebedef;

$gray-base: #3c4b64 !default;
$gray-100: #ebedef !default;
$gray-200: #d8dbe0 !default;
$gray-300: #c4c9d0 !default;
$gray-400: #b1b7c1 !default;
$gray-500: #9da5b1 !default;
$gray-600: #8a93a2 !default;
$gray-700: #768192 !default;
$gray-800: #636f83 !default;
$gray-900: #4f5d73 !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);
