.loading-full {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 9999;

  &.active {
    display: flex;
  }
}
