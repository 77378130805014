.loading {
  margin: auto;
  svg {
    -webkit-animation: rotate-scale-up 1s linear both;
    animation: rotate-scale-up 1s infinite linear both;
    transform-origin: 50% 50%;
  }
  .logoaja {
  }
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(0.75);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(0.75);
  }
}
